import styled from "styled-components";

export const Title = styled.h3`
font-weight: bold;
font-size: 25px;
flex: 1;
padding: 8px;
color: black;
  text-decoration: none;
  &:hover {
    color: gray;
  }
 
`;