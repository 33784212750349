import styled from "styled-components";

export const PostContainer = styled.div`
display: flex;
flex-direction: column;
max-width: 80%;
margin: auto;
margin-top: 30px;

`
export const Form = styled.form`
padding-bottom: 7%;
display: flex;
flex-direction: column;
width: 90%;
margin: auto;
`

